<template>
  <div class="home">
    <Nav/>
    <div class="content">
      <Mynode />
    </div>
  </div>
</template>

<script>
  import { lang } from "../../tools";
  import Nav from '../../components/Nav/Index'
  import Node from '../Node/index'
  import Mynode from '../Home/Mynode'
  export default {
    name: 'Home',
    components: {Nav,Node,Mynode},
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
      lang(key){
        return lang(key);
      }
    },
  }

</script>

<style scoped lang="less">
 
  @media (max-width: 768px){
    .content{padding:65px 10px 80px;}
  }
</style>
